import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Blogs from "../components/Blogs"
import SEO from "../components/SEO"

const Blog = ({
  data: {
    allStrapiBlog: { nodes: blogs },
  },
}) => {
  return (
    <Layout>
      <SEO
        title="Blog"
        description="my thoughts on the learning journey 文組轉職工程師 全職媽媽 二度就業 react.js 數學不好 學程式"
      />
      <section className="blog-page">
        <Blogs blogs={blogs} title="blog"></Blogs>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiBlog(sort: { fields: date, order: DESC }) {
      nodes {
        slug
        desc
        date(formatString: "MMMM Do, yyyy")
        id
        title
        category
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
export default Blog
